import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PlatformEnvDataProvider,
	SiteFeatureConfigSymbol,
	IMultilingual,
	MultilingualSiteConfig,
} from '@wix/thunderbolt-symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

import { name } from './symbols'
import { ICookiesManager, CookiesManagerSymbol } from 'feature-cookies-manager'
import { resolveLanguageUrl } from './urlResolver'

const multilingualFactory = (
	siteFeatureConfig: MultilingualSiteConfig,
	cookieManager: ICookiesManager,
	urlHistoryManager: IUrlHistoryManager
): IMultilingual & PlatformEnvDataProvider => {
	const {
		isEnabled,
		isOriginalLanguage,
		siteLanguages,
		currentLanguage,
		originalLanguage,
		domain,
	} = siteFeatureConfig
	const setCurrentLanguage = (languageCode: string) => {
		const chosenLanguage = siteLanguages.find((lang) => lang.languageCode === languageCode)!
		if (!chosenLanguage) {
			throw new Error(`language code "${languageCode}" is invalid`)
		}

		cookieManager.writeCookie('wixLanguage', languageCode, 'functional', {
			daysExpire: 6 * 30,
			domain,
		})

		const parsedUrl = urlHistoryManager.getParsedUrl()
		const isNextLanguageOriginal = originalLanguage.languageCode !== languageCode
		const languageUrl = resolveLanguageUrl(
			chosenLanguage.resolutionMethod,
			parsedUrl,
			isNextLanguageOriginal ? languageCode : undefined
		)
		location.assign(languageUrl)
	}

	return {
		platformEnvData: {
			multilingual: {
				isEnabled,
				isOriginalLanguage,
				siteLanguages,
				currentLanguage,
			},
		},
		isEnabled,
		isOriginalLanguage,
		siteLanguages,
		currentLanguage,
		setCurrentLanguage,
	}
}

export const Multilingual = withDependencies(
	[named(SiteFeatureConfigSymbol, name), CookiesManagerSymbol, UrlHistoryManagerSymbol],
	multilingualFactory
)
