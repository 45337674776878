import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { Multilingual } from './multilingual'
import { MultilingualSymbol } from './symbols'
import { PlatformEvnDataProviderSymbol } from '@wix/thunderbolt-symbols'

export const site: ContainerModuleLoader = (bind) => {
	bind(MultilingualSymbol, PlatformEvnDataProviderSymbol).to(Multilingual)
}

export * from './symbols'
